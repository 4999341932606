import axios from "axios";
import CryptoJS from "crypto-js";
import ReactJson from "react-json-view";
import dayjs from "dayjs";
import { Button, Modal, Pagination, Space, Table, Input, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import { EyeOutlined, ExportOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const Search = Input.Search;
const { RangePicker } = DatePicker;

const Home = () => {
  const ENC_KEY = "66e1f02e5864bd2ad6cf6bdff24a573d";

  const dateFormat = "YYYY/MM/DD";
  const [orderColumn] = useState('"createdAt"');
  const [orderDirection] = useState('"DESC"');
  const [column] = useState("all");
  const [showModel, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const encrypt = (val) => {
    const encrypted = CryptoJS.AES.encrypt(val, ENC_KEY).toString();
    return encrypted;
  };

  const loadData = async (pageSize, limitSize, searchData = "", isExport = false, startDate = "", endDate = "") => {
    setIsLoading(true);
    try {
      const phrase = JSON.stringify("rK5QeD9P71pXBgaVArLh78PwhXkt4tWQ-" + parseInt(new Date().getTime() / 1000));
      const encrypted_key = encrypt(phrase);
      const res = await axios.get(
        `https://logger.paysitter.demos.work/log?limit=${limitSize}&skip=${
          (pageSize - 1) * limitSize
        }${`&search=${searchData}&searchColumn=${column}`}&start=${startDate}&end=${endDate}&isExport=${isExport}&order=[${[
          orderColumn,
          orderDirection,
        ]}]`,
        {
          headers: {
            Authorization: `${encrypted_key}`,
            user: "admin@gmail.com",
          },
        }
      );
      if (res.data.status === 0) {
        setIsLoading(false);
        setList([]);
        toast.error("Data not available");
      }
      // if (!isExport && (res?.data.status === 1 || res?.data.status === "1")) {
      if (res?.data.status === 1 || res?.data.status === "1") {
        setIsLoading(false);
        if (isExport) {
          const byteCharacters = atob(res.data.data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          // Create a blob from the byteArray
          const blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          // Create a URL for the blob
          const url = URL.createObjectURL(blob);
          // Create a link element and simulate a click to download the file
          const link = document.createElement("a");
          link.href = url;
          const date = dayjs().format("MMMM D, YYYY h:mm:ss A");
          link.setAttribute("download", `report-${date}.xlsx`); // .xlsx
          document.body.appendChild(link);
          link.click();
        } else {
          setList(res.data.data);
          setTotal(res.data.total);
        }
      }
      // else {
      //   setIsLoading(false);
      // }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    loadData(page, limit, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
      width: 150,
      sorter: (a, b) => a.event.length - b.event.length,
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 100,
      sorter: (a, b) => a.type.length - b.type.length,
    },
    {
      title: "User Id",
      dataIndex: "userId",
      key: "userId",
      width: 100,
      render: (value, row) => (
        <p>
          {row && row.object && row.object.response && row.object.response.data && row.object.response.data.id
            ? row.object.response.data.id
            : "-"}
        </p>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipse: true,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Platform",
      key: "platform",
      dataIndex: "platform",
      width: 100,
      sorter: (a, b) => a.platform.length - b.platform.length,
    },
    {
      title: "IP",
      key: "ip",
      dataIndex: "ip",
      width: 150,
      // sorter: (a, b) => a.platform.length - b.platform.length,
    },
    {
      title: "User Agent",
      key: "user_agent",
      dataIndex: "user_agent",
      ellipses: true,
      // sorter: (a, b) => a.user_agent.length - b.user_agent.length,
    },
    {
      title: "Host",
      key: "host",
      dataIndex: "host",
      // sorter: (a, b) => a.host.length - b.host.length,
    },
    {
      title: "Date",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (value) => {
        return <>{dayjs(value).format("dddd, MMMM D, YYYY h:mm A")}</>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          <div
            onClick={() => {
              setModalData(record);
              setShowModal(true);
            }}
          >
            <EyeOutlined />
          </div>
        </Space>
      ),
    },
  ];

  const onShowSizeChange = (pageSize, current) => {
    setPage(pageSize);
    setLimit(current);
    loadData(pageSize, current, searchText, false, startDate, endDate);
  };

  const onSearch = (value) => {
    if (value) {
      setPage(1);
      setLimit(100);
      setSearchText(value);
      loadData(1, 100, value, false, startDate, endDate);
    } else {
      setPage(1);
      setLimit(100);
      setSearchText("");
      loadData(1, 100, "", false, startDate, endDate);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    const params = {};
    if (sorter.hasOwnProperty("column")) {
      params.order = { field: sorter.field, dir: sorter.order };
    }
  };

  const handleReset = () => {
    setPage(1);
    setLimit(100);
    loadData(page, limit, "", false, "", "");
  };

  const handleExport = () => {
    loadData(page, limit, searchText, true);
  };

  const handleDatePicker = (param) => {
    if (param?.length === 2) {
      setStartDate(dayjs(param[0]).format("YYYY-MM-DD"));
      setEndDate(dayjs(param[1]).format("YYYY-MM-DD"));
      loadData(
        page,
        limit,
        searchText,
        false,
        dayjs(param[0]).format("YYYY-MM-DD"),
        dayjs(param[1]).format("YYYY-MM-DD")
      );
    } else {
      setStartDate("");
      setEndDate("");
      loadData(page, limit, searchText, false, "", "");
    }
  };

  return (
    <div className="container-fluid mt-4">
      <div className="d-flex justify-content-end align-items-center mb-3">
        <Search
          placeholder="Search By IP,Name,Type,Host,User Agent,Event"
          onSearch={onSearch}
          style={{ width: 400 }}
          allowClear
        />
        <RangePicker
          // defaultValue={[dayjs(dayjs(), dateFormat), dayjs("2015/01/01", dateFormat)]}
          format={dateFormat}
          onChange={handleDatePicker}
          className="mx-2"
        />
        <Button type="primary" className="mx-2" onClick={handleReset}>
          Reload
        </Button>
        <Button loading={isLoading} className="mx-2" onClick={handleExport} icon={<ExportOutlined />}>
          Export
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={list}
        bordered
        loading={isLoading}
        scroll={{ x: 2000, y: "calc(100vh - 280px)" }}
        rowKey={(record) => record.id}
        size="middle"
        pagination={false}
        rowClassName={{ width: "100%" }}
        onChange={handleChange}
      />
      <div className="p-3 d-flex justify-content-end">
        <Pagination
          defaultPageSize={100}
          current={page}
          pageSizeOptions={[100, 130, 150]}
          total={total}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          onShowSizeChange={onShowSizeChange}
          onChange={onShowSizeChange}
        />
      </div>
      <Modal
        open={showModel}
        onCancel={() => {
          setShowModal(false);
        }}
        bodyStyle={{ height: 400, overflow: "scroll" }}
        title={<div>Log Details</div>}
        footer={[
          <div key="commentDeleteButtonDeleteFooter">
            <Button
              type="primary"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
          </div>,
        ]}
      >
        <div className="card modal-body">
          {modalData && (
            <div className="row font-monospace">
              <p className="m-0 bg-light">
                <span className="fw-bold">Event: </span>
                {modalData?.event}
              </p>
              <p className="m-0">
                <span className="fw-bold">Host: </span>
                {modalData?.host}
              </p>
              <p className="m-0 bg-light">
                <span className="fw-bold">Ip: </span>
                {modalData?.ip}
              </p>
              <p className="m-0">
                <span className="fw-bold">Method: </span>
                {modalData?.type}
              </p>
              <p className="m-0 bg-light">
                <span className="fw-bold">Name: </span> {modalData?.name}
              </p>
              <p className="m-0">
                <span className="fw-bold">Platform: </span> {modalData?.platform}
              </p>
              <p className="m-0 bg-light">
                <span className="fw-bold">Type: </span> {modalData?.type}
              </p>
              <p className="m-0">
                <span className="fw-bold">User Agent: </span> {modalData?.user_agent}
              </p>
              <p className="m-0 bg-light">
                <span className="fw-bold">Created At: </span>{" "}
                {dayjs(modalData?.createdAt).format("dddd, MMMM D, YYYY h:mm A")}
              </p>
              <p className="m-0">
                <span className="fw-bold">Created At Ip: </span>
                {modalData?.createdAtIp}
              </p>
              <div className="m-0 bg-light">
                <span className="fw-bold">Object:</span>{" "}
                <div className="bg-light p-2 m-0 border object-paragraph">
                  {" "}
                  {modalData?.object && (
                    <ReactJson src={modalData.object} />
                    // <JsonView data={modalData.object} style={defaultStyles} />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Home;
