import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext(null);

const getUser = () => {
  const userData = localStorage.getItem('log-info-user');
  return userData ? userData : null;
};

const AuthContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(getUser());
  const values = React.useMemo(
    () => ({
      userData,
      setUserData,
    }),
    [userData],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContextProvider;
