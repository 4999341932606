import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import { Page404 } from "./components/Page404";
import { Fragment } from "react";
import { Layout } from "antd";

const { Content } = Layout;

const contentStyle = {
  textAlign: "center",
  minHeight: "80vh",
  backgroundColor: "#fff",
};

function App() {
  // const navigate = useNavigate();
  // const { setUserData } = useAuth();

  // const logout = async () => {
  //   localStorage.clear();
  //   setUserData(null);
  //   window.setTimeout(() => {
  //     navigate("/");
  //   }, 1000);
  // };

  return (
    <Fragment>
      <Layout>
        {/* <Header style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff", borderBottom: "1px solid lightgrey" }}>
          <div>
            <h4>Log</h4>
          </div>
          <div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: 18, color: "red", cursor: "pointer" }} onClick={logout}>
              <span style={{ marginRight: 10, marginTop: -6 }}>
                <LogoutOutlined />
              </span>
              Logout
            </div>
          </div>
        </Header> */}
        <Content style={contentStyle}>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Content>
      </Layout>
    </Fragment>
  );
}

export default App;
